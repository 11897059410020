import React from "react"
import { Grid } from "@material-ui/core"
import * as S from "./footer.styles"
import ContactBar from "../../layouts/ContactBar/ContactBar"

const Footer = ({ className, hideFooterContactForm }) => {
  const solutionsItems = [
    { url: "/solutions/enterprise/", title: "Enterprise" },
    { url: "/solutions/high-performance-compute/", title: "HPC" },
    { url: "/solutions/edge/", title: "Edge" },
    { url: "/solutions/custom/", title: "Custom" },
    { url: "/solutions/standards/", title: "Standards" },
    { url: "/solutions/differentiators/", title: "Differentiators" },
  ]

  const servicesItems = [
    { url: "/services/finance/", title: "Finance" },
    { url: "/services/develop/", title: "Develop" },
    { url: "/services/planning/", title: "Planning" },
    { url: "/services/design/", title: "Design" },
    { url: "/services/construction/", title: "Construction" },
    { url: "/services/data-center-operations/", title: "DC Operations" },
  ]

  const esgItems = [
    { url: "/esg/purpose-driven-culture/", title: "Purpose-Driven Culture" },
    { url: "/esg/modular-sustainability/", title: "Modular Sustainability" },
  ]

  const aboutItems = [
    { url: "/about/team/", title: "Team" },
    { url: "/about/partners/", title: "Partners" },
    { url: "/about/newsroom/", title: "Newsroom" },
    { url: "/about/portfolio/", title: "Portfolio" },
  ]

  return (
    <S.Footer className={className}>
      {!hideFooterContactForm && <ContactBar />}

      <S.FooterContainer className="sitemap">
        <Grid container spacing={3}>
          <Grid item md={2} sm={2} xs={12} className="logo">
            <div className="brand self">
              <a
                href="https://imhpc.ai/?utm_source=imdatacenters.com"
                target="_blank"
              >
                <S.IMHPCACILogo />
              </a>
              <p>
                Visit{" "}
                <a
                  href="https://imhpc.ai/?utm_source=imdatacenters.com"
                  target="_blank"
                  aria-label="iM HPC AI"
                >
                  imhpc.ai
                </a>{" "}
                to learn about our dedicated HPCaaS Nodes and MLOPS Consulting
                Services
              </p>
            </div>

            <div className="brand">
              <a
                href="https://imcritical.com/?utm_source=imdatacenters.com"
                target="_blank"
                aria-label="iM Critical"
              >
                <S.IMCriticalLogo />
              </a>
              <p>
                Visit{" "}
                <a
                  href="https://imcritical.com/?utm_source=imdatacenters.com"
                  target="_blank"
                >
                  imcritical.com
                </a>{" "}
                to view our Portfolio of High-Performance Data Centers,
                Colocation &amp; IT Services
              </p>
            </div>
          </Grid>
          <Grid container item md={10} sm={10} xs={12}>
            <Grid item md={3} sm={6} xs={12}>
              <h6 className="section">
                <a href="/solutions/">Solutions</a>
              </h6>
              <ul className="nav">
                {solutionsItems.map((item, index) => (
                  <li key={`item${index}`}>
                    <a href={item.url}>{item.title}</a>
                  </li>
                ))}
              </ul>
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <h6 className="section">
                <a href="/services/">Services</a>
              </h6>
              <ul className="nav">
                {servicesItems.map((item, index) => (
                  <li key={`item${index}`}>
                    <a href={item.url}>{item.title}</a>
                  </li>
                ))}
              </ul>
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <h6 className="section">
                <a href="/esg/">ESG</a>
              </h6>
              <ul className="nav">
                {esgItems.map((item, index) => (
                  <li key={`item${index}`}>
                    <a href={item.url}>{item.title}</a>
                  </li>
                ))}
              </ul>
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <h6 className="section">
                <a href="/about/">About</a>
              </h6>
              <ul className="nav">
                {aboutItems.map((item, index) => (
                  <li key={`item${index}`}>
                    <a href={item.url}>{item.title}</a>
                  </li>
                ))}
              </ul>
              <h6 className="section">
                <a href="/contact/">Contact</a>
              </h6>
            </Grid>
          </Grid>
        </Grid>
      </S.FooterContainer>

      <S.FooterContainer className="legal">
        <Grid container spacing={2}>
          <Grid item md={6} xs={12} className="privacy">
            <a href="/privacy/">Privacy Policy</a>
          </Grid>

          <Grid item md={6} xs={12} className="copyright">
            &copy; {new Date().getFullYear()} iM Data Centers
          </Grid>
        </Grid>
      </S.FooterContainer>
    </S.Footer>
  )
}

export default Footer
