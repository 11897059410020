import styled from "styled-components"
import Container from "@material-ui/core/Container"
import backgroundImage from "./bar-bg.svg"

export const ContactBar = styled.div`
  background: #0C1427 url(${backgroundImage}) 100% 50% no-repeat;
  background-size: cover;
  padding: 2rem 3rem;
  position: relative;

  &::before {
    position: absolute;
    content: "";
    z-index: 1;
    background-color: rgba(0, 0, 0, .30);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  ${({ theme }) => theme.breakpoints.down("md")} {
    background-position: 60% 50%;
  }
`

export const BarContainer = styled(Container)`
  position: relative;
  z-index: 10;
  // padding-left: 0 !important;
  // padding-right: 0 !important;

  .copy, .form {
    padding-left: 0;
    padding-right: 0;
  }

  .title {
    margin: 0 0 10px 0;
    font-size: 43px;
    font-weight: bold;
    text-shadow: 1px 1px 4px rgba(0, 0, 0, .4);
  }

  .subtitle {
    margin: 0;
    font-size: 19px;
    font-weight: 600;
    position: relative;
    overflow: hidden;

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      background-color: #3b78a9;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .round_button {
    margin: 12px 0 0 0;
    padding: 0 12px 0 18px;
    font-size: 17px;
    line-height: 58px;
    color: #fff;
    text-decoration: none;
    background: #2c6d89;
    border-radius: 60px;
    border: 0;
    display: inline-block;
  }

  button {
    font-family: "Roboto", sans-serif;
    cursor: pointer;
  }

  p {
    margin: 0 0 30px 0;
    font-size: 1.4em;
    font-weight: 500;
    line-height: 22px;

    ${({ theme }) => theme.breakpoints.down("md")} {
      margin-bottom: 0;
    }
  }
`