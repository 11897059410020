import styled from "styled-components"

export const Form = styled.form`
  padding: 18px 28px;
  color: #000;
  overflow: hidden;

  ${({ theme }) => theme.breakpoints.down("md")} {
    padding: 0;
  }

  input[type=text], input[type=email], textarea {
      border-width: 0 0 1px 0;
      color: #fff;
      width: 100%;
      font-size: 1em;
      font-family: ${({ theme }) => theme.fonts.primary};
      border-bottom-color: #fff;
      padding: 5px 5px 15px 5px;
      margin-bottom: 10px;
      background-color: transparent;

      &::placeholder {
        color: #ccc;
      }
  }

  textarea {
    height: 100px;
  }

  button[type=submit] {
    cursor: pointer;
    padding: 0 34px;
    margin: 0;
    border: 0;
    font-size: 19px;
    font-weight: 600;
    line-height: 50px;
    text-decoration: none;
    color: #fff;
    background: #2748A0;
    transition: all .25s ease-in-out;
    background-image: linear-gradient(to left,transparent,transparent 50%,#07B1C4 50%,#2680EB);
    background-size: 201% 100%;
    background-position: 100% 0;
    float: right;

    &:hover {
        background-position: 0 0;
        color: #fff;
        border-color: #2680EB;
    }
  }
`