import styled from "styled-components"
import Container from "@material-ui/core/Container"
import IMDataCentersSquare from "../../assets/im-square.svg"
import IMCriticalSquare from "../../assets/im-critical-square.svg"
import IMHPCAISquare from "../../assets/im-hpcai-square.svg"

export const FooterContainer = styled(Container)`
  padding-top: 4em;

  ${({ theme }) => theme.breakpoints.down("xs")} {
    padding-top: 20px;
  }

  .logo {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;

    svg {
      margin: 0 auto 10px;
    }

    ${({ theme }) => theme.breakpoints.down("xs")} {
      order: 100;

      .self {
        display: none;
      }
    }
  }

  .copyright {
    text-align: right;

    ${({ theme }) => theme.breakpoints.down("xs")} {
      text-align: left;
    }
  }
  &.legal a {
    color: #fff;
    text-decoration: none;
    transition: all 0.25s linear;

    &:hover {
      color: ${({ theme }) => theme.palette.secondary.main};
    }
  }
  ${({ theme }) => theme.breakpoints.down("xs")} {
    .privacy {
      text-align: left;
    }
  }
`

export const Footer = styled.footer`
  color: white;
  padding: 0 0 2em;
  background-color: #040e1c;

  .section {
    font-size: 1.3em;
    color: ${({ theme }) => theme.palette.secondary.main};
    font-weight: 500;
    margin: 0 0 18px;

    a {
      color: inherit;
      text-decoration: none;
    }
  }

  .logo {
    width: 215px;

    .brand p {
      font-size: 0.7rem;
      margin: 0;

      a {
        color: ${({ theme }) => theme.palette.secondary.main};
      }
    }
  }

  ul.nav {
    margin: 0 16px 40px 0;
    padding: 0;
    list-style-type: none;

    // &:last-child { margin-bottom: 0; }

    li {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    a {
      color: #fff;
      text-decoration: none;
      transition: all 0.25s linear;

      &:hover {
        color: ${({ theme }) => theme.palette.secondary.main};
      }
    }
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    .MuiContainer-root {
      padding-right: 2.5em;
      padding-left: 2.5em;
    }
  }
`
export const IMDataCentersLogo = styled(IMDataCentersSquare)`
  width: 100%;
  max-width: 80px;
  margin-bottom: 20px;
`

export const IMCriticalLogo = styled(IMCriticalSquare)`
  width: 100%;
  max-width: 80px;
  margin-bottom: 20px;
  display: block;
`

export const IMHPCACILogo = styled(IMHPCAISquare)`
  width: 100%;
  max-width: 80px;
  margin-bottom: 20px;
  display: block;
`
