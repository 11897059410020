import React from "react"
import * as S from "./top-nav.styles.jsx"
import parse from "html-react-parser"
import { useStaticQuery, graphql } from "gatsby"

const TopNav = (props) => {
    const WPMenuQuery = useStaticQuery(graphql`
    {
        topMenu: wpMenu(name: {eq: "Top Menu"}) {
            menuItems {
                nodes {
                    label
                    url
                    parentId
                    target
                    path
                    locations
                    id
                    cssClasses
                }
            }
        }
        primaryMenu: wpMenu(name: {eq: "Primary"}) {
            menuItems {
                nodes {
                    label
                    url
                    parentId
                    target
                    path
                    locations
                    id
                    cssClasses
                    description
                    childItems {
                        nodes {
                            label
                            url
                            parentId
                            target
                            path
                            locations
                            id
                            cssClasses
                            description
                        }
                    }
                }
            }
        }
    }
    `)

    const flatListToHierarchical = (
        data = [],
        { idKey = "id", parentKey = "parentId", childrenKey = "children" } = {}
      ) => {
        const tree = []
        const childrenOf = {}
        data.forEach(item => {
          const newItem = { ...item }
          const { [idKey]: id, [parentKey]: parentId = 0 } = newItem
          childrenOf[id] = childrenOf[id] || []
          newItem[childrenKey] = childrenOf[id]
          parentId
            ? (childrenOf[parentId] = childrenOf[parentId] || []).push(newItem)
            : tree.push(newItem)
        })
        return tree
    
    }

    let primaryMenu = flatListToHierarchical(WPMenuQuery.primaryMenu.menuItems.nodes);
    let topMenu = flatListToHierarchical(WPMenuQuery.topMenu.menuItems.nodes);

    return (
    <S.Nav className={`nav_area ${props.isActive === true ? "mobile_open" : "mobile_closed"}`}>
        <S.TopNav className="navbar-nav topNav">
            {topMenu.map((menuItem, i) => (
                <li className="nav-item" key={`topnav-${i}`}>
                    <a className={menuItem.cssClasses + " nav-link"} href={menuItem.path} target={menuItem.target}>{menuItem.label}</a>

                    {menuItem.children && menuItem.children.length > 0 && (
                    <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                        {menuItem.children.map((childNav, i2) => (
                            <li className={"child-nav " + childNav.cssClasses} key={`topnav-child-${i2}`}>
                                <a href={childNav.path} target={childNav.target}>
                                    {parse(childNav.label)}
                                </a>
                            </li>
                        ))}
                    </ul>
                    )}
                </li>
            ))}
        </S.TopNav>

        <S.BottomNav className="navbar-nav bottomNav">
            {primaryMenu.map((menuItem, i) => (
                <li className={"navbar-nav " + menuItem.children.length > 0 ? "dropdown" : null} key={`primarynav-${i}`}>
                    <a className="nav-link" href={menuItem.path} aria-expanded="false">{parse(menuItem.label)}</a>
                    <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                        <li className="child-nav full-width">
                                <a href={menuItem.path} target={menuItem.target}>
                                    <strong>{parse(menuItem.label)}</strong>
                                    <span className="description">{parse(menuItem.description)}</span>
                                </a>
                        </li>
                        {menuItem.children.map((childNav, i2) => (
                            <li className={"child-nav " + childNav.cssClasses}  key={`primarynav-chilld-${i2}`}>
                                <a href={childNav.path} target={childNav.target}>
                                    <strong>{parse(childNav.label)}</strong>
                                    <span className="description">{parse(childNav.description)}</span>
                                </a>
                            </li>
                        ))}
                    </ul>
                </li>
            ))}
        </S.BottomNav>
    </S.Nav>
    )
}

export default TopNav
