import React from "react"
import { Grid } from "@material-ui/core"
import ContactForm from "../../components/contact-form/contact-form.component"
import * as S from "./ContactBar.styles"

const ContactBar = () => {
    return (
    <S.ContactBar>
        <S.BarContainer>
            <Grid container spacing={4} alignItems="center">
                <Grid item md={4} xs={12} className="copy">
                    <h5 className="title">Get In Touch</h5>
                    <p>Let's Get to Work</p>
                </Grid>
                <Grid item md={8} xs={12} className="form">
                    <ContactForm />
                </Grid>
            </Grid>
        </S.BarContainer>
    </S.ContactBar>
    )
}

export default ContactBar